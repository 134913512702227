import { Swiper, Autoplay, Navigation, Pagination } from "swiper";

const initializeSwiperCarousel = () => {
    if (!window.swiperCarouselInitialized) {
        const carousels = $(".swiper-carousel-glide");

        carousels.each((index, carousel) => {
            const selector = "." + $(carousel).data().id + " .swiper";
            const timer    = $(carousel).data().timer;
            const slidesPerView    = $(carousel).data().view;

            const firstBanner = $(carousel).find(".banner").get(0);
            const firstImage  = $(firstBanner).find("img").get(0);

            const navigationEnabled = $(carousel).data("navigation-enabled");
            const paginationEnabled = $(carousel).data("pagination-enabled");

            const modules = [];

            modules.push(Autoplay);

            if (paginationEnabled) {
                modules.push(Pagination);
            }

            if (navigationEnabled) {
                modules.push(Navigation);
            }

            $(firstImage).attr("loading", "eager");

            new Swiper(selector, {
                loop: false,
                autoplay: {
                    delay: +timer,
                    pauseOnMouseEnter: true
                },
                lazyPreloadPrevNext: 1,
                modules: modules,
                parallax: true,
                navigation: {
                    enabled: true,
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper .js-swiper-pagination",
                    type: "bullets",
                    clickable: true,
                },
                breakpoints: {
                    // when window width is >= 544px
                    544: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    // when window width is >= 769px
                    769: {
                        slidesPerView: slidesPerView < 2 ? slidesPerView : 2,
                        spaceBetween: 30
                    },
                    // when window width is >= 1080px
                    1080: {
                        slidesPerView: slidesPerView,
                        spaceBetween: 30
                    }
                },
            });
        });

        window.swiperCarouselInitialized = true;
    }
};

export default initializeSwiperCarousel;
